import React from "react"

import Layout from "../components/common/layout"
import SEO from "../components/common/seo"
import ReactMarkdown from "react-markdown";
import {graphql} from "gatsby";
import {StaticImage} from "gatsby-plugin-image";
import {sortAsc} from "../utils/global-functions";
import rehypeRaw from "rehype-raw";

const WhyTecalisPage = ({data, pageContext}) => {
    const lang = pageContext.locale.path.default;
    const whyTecalis = data.allStrapiWhyTecalis.edges[0].node;
    const reasons = whyTecalis.reasons.sort(sortAsc);
    const customParagraph = (addClassName = '') => {
        let className = 'font-size-lg text-black-80 mb-6 spacing';

        if (addClassName !== '') {
            className += ' ' + addClassName;
        }

        return {
            p: ({node, ...props}) => <p className={className} {...props} />
        }
    }

    // console.log(whyTecalis);
    // console.log('reasons', reasons);

    return (
        <Layout pageContext={pageContext} cookies={data.cookies}>
            <SEO lang={lang} title={whyTecalis.seo.title} description={whyTecalis.seo.meta_description}/>

            {/** INNOVACIÓN EN MODO VELOCITY (DESKTOP)
             ================================================== */}
            <section data-jarallax="" data-speed=".8" className="pt-12 pb-10 pt-md-13 pb-md-12 bg-white why-tecalis-hero">
                <div className="overlay-bg bg-pattern-2"/>

                <img src={require('../img/infi.svg').default} className="" alt="Tecalis" width="900" style={{position: 'absolute', opacity: '.4', top: '200px', right: '-300px'}}/>

                <div className="container">
                    <div className="position-absolute top-right text-primary-dark mt-n12" style={{position: 'absolute', zIndex: '-99', transform: 'rotate(135deg)'}}>
                        <img src={require('../img/infi.svg').default} alt="..."/>
                    </div>

                    <div className="row align-items-center">
                        <div className="col-xl-12">
                            <h1 align="left" className="display-2 mb-8 font-weight-black text-white spacing font-why">
                                {whyTecalis.title}<span className="blink">_</span>
                            </h1>
                        </div>

                        <br/>

                        <div className="col-12 col-lg-8 col-xl-8">
                            <h3 align="left" className="text-white spacing">
                                {whyTecalis.description}
                            </h3>
                        </div>
                    </div>
                </div>
            </section>

            <section className="pt-8 pb-6 pt-md-11 bg-white border-top" style={{position: 'relative', zIndex: '999'}}>
                <div className="container">
                    <div className="row mt-3">
                        <div className="col-12 col-md-6 col-lg-5" data-aos="">
                            <h2 className="display-4 font-weight-bold spacing">
                                {whyTecalis.transformation_title}
                                <br/><br/>
                            </h2>

                            <ReactMarkdown children={whyTecalis.transformation_description} rehypePlugins={[rehypeRaw]} components={customParagraph()}/>
                        </div>

                        <div className="col-12 col-md-6 col-lg-6 offset-lg-1">
                            <div className="position-absolute top-center top-md-left text-warning mt-n2 ml-md-n8" style={{position: 'absolute', zIndex: '-10', top: '50px'}}>
                                <img src={require("../img/products/ic_decoration.svg").default} alt="Decoration"/>
                            </div>

                            <img src={require('../images/why-tecalis_team.png').default} width="100%" className="img-fluid mw-130 float-left mb-6 mb-md-0" alt="Why Tecalis"/>
                        </div>
                    </div>
                </div>
            </section>

            <section className="pt-8 pt-md-9 bg-white">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-12 col-lg-12">
                            <div className="card rounded-lg shadow-lg mt-8 mb-6 mb-md-0" style={{zIndex: '1'}} data-aos="fade-up">
                                <div className="card-body py-6 py-md-8">
                                    <div className="row justify-content-center">
                                        <div className="col-12 col-xl-9">
                                            <br/><br/>
                                            <img className="mb-6" src={whyTecalis.opinion.company_logo.localFile.publicURL} height="60" alt="Logo"/>
                                            <br/>
                                            <img className="mb-4" src={require("../img/fivestars.png").default} width="115" alt="rate"/>
                                            <br/>
                                            <h2 className="text-black-80 font-weight-medium spacing mb-5 mb-md-7 why--op">
                                                “{whyTecalis.opinion.message}”
                                            </h2>

                                            <div className="media">
                                                <img className="rounded-circle mr-3"
                                                     src={whyTecalis.opinion.photo.localFile.publicURL}
                                                     width="60" alt="Álvaro Morejón"/>
                                                <div className="media-body">
                                                    <h3 className=" spacing font-weight-bold mb-0">
                                                        <b>{whyTecalis.opinion.name}</b>
                                                    </h3>
                                                    <p className="font-size-lg spacing text-muted mb-2 why--op">
                                                        {whyTecalis.opinion.job_position}
                                                    </p>
                                                    <br/><br/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className="position-relative mt-n15">
                <div className="shape shape-bottom shape-fluid-x svg-shim text-dark">
                    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48h2880V0h-720C1442.5 52 720 0 720 0H0v48z" fill="#f8f8f8"/>
                    </svg>
                </div>
            </div>

            <section className="pt-15 bg-grey pb-10">
                <div className="container pt-8 pt-md-11">
                    <h2 align="center" className="display-4 font-weight-bold text-black-80 spacing pt-10">
                        {whyTecalis.reasons_title}
                    </h2>

                    <hr className="hr-sm my-6 my-md-8 border-black-80"/>

                    <br/>

                    <div className="row">
                        <div className="col-xl-12">
                            <div className="row">
                                {reasons.map((reason, i) => {
                                    return <div className="col-12 col-xl-4">
                                        <div className="row">
                                            <div className="col-auto col-xl-12">
                                                <div className="row no-gutters align-items-center mb-md-1">
                                                    <div className="col-auto">
                                                        <div className="btn btn-sm btn-rounded-circle btn-white disabled opacity-1" style={{cursor: 'auto !important'}}>
                                                            <span className="font-size-lg font-weight-black">{reason.order}</span>
                                                        </div>
                                                    </div>

                                                    <div className="col">
                                                        <hr className="tecalis-dash d-none d-md-block mr-n7"/>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col col-md-12 ml-n5 ml-md-0">
                                                <br/>

                                                <h3 className="text-black-80 font-weight-black spacing">
                                                    {reason.title}
                                                </h3>

                                                <p className="font-size-sm text-black-80 mb-6 spacing" style={{minHeight: '90px', width: '90%'}}>
                                                    {reason.description}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                })}

                            </div>
                        </div>
                    </div>
                </div>
            </section>


            {/** ESTRUCTURA PARA EL CRECIMIENTO
             ================================================== */
            }
            <section className="position-relative py-8 py-md-13 mb-0 bg-white">

                {/** Content */}
                <div className="container">
                    <div className="row align-items-center">

                        <div className="col-xl-7" data-aos="fade-up">

                            {/** Heading */}
                            <h2 className="display-4 font-weight-bold spacing">
                                {whyTecalis.innovation_title}
                                <br/><br/>
                            </h2>

                            <ReactMarkdown children={whyTecalis.innovation_description} rehypePlugins={[rehypeRaw]} components={customParagraph()}/>

                            <p className="font-size-lg text-black-80 mb-6 spacing"><b><span
                                className="underlines-tecalis">{whyTecalis.innovation_sentence}</span></b>
                            </p>
                        </div>

                        <div className="col-xl-1"/>

                        <div className="col-xl-4">
                            <StaticImage src={"../img/why-tecalis_03.webp"} alt="..." className="img-fluid mb-6 mb-md-0" data-aos="fade-down"/>
                            <br/><br/>
                        </div>

                    </div>
                    {/** / .row */}
                </div>
                {/** / .container */}

            </section>

            {/** TRANSFORMANDO DIGITALMENTE A LAS MEJORES EMPRESAS
             ==================================================================== */
            }
            <section className="bg-negro">
                <div className="container d-flex flex-column">
                    <div className="row align-items-center justify-content-between no-gutters min-vh-50">
                        <div className="col-12 col-md-5 align-self-stretch">
                            <img src={require("../images/why-tecalis_04.png").default} className="d-md-none img-cover" alt="..."/>

                            <div className="d-none d-md-block vw-50 h-100 float-right bg-cover"
                                 style={{backgroundImage: "url(" + require("../img/why-tecalis_04.webp").default + ")"}}/>
                        </div>

                        <div className="col-12 col-md-6 py-8 py-md-11">
                            <br/>

                            <h2 className="display-4 font-weight-bold spacing text-white">
                                {whyTecalis.partner_title}
                                <br/>
                            </h2>

                            <hr className="hr-sm my-6 my-md-8 border-white"/>

                            <ReactMarkdown children={whyTecalis.partner_description} rehypePlugins={[rehypeRaw]} components={customParagraph('text-white')}/>

                            <br/>

                            <p className="font-size-lg text-white mb-6 spacing">
                                <img src={require("../images/why-tecalis_logo-orange.png").default} width="72px" alt="..."/>
                            </p>

                        </div>
                    </div>
                    {/** / .row */}
                </div>
            </section>

        </Layout>
    )
};

export default WhyTecalisPage

export const whyPageQuery = graphql
    `query($lang: String) {
        allStrapiWhyTecalis ( filter: { locale: { eq: $lang } } ) {
            edges {
                node {
                    seo {
                        title,
                        meta_description
                    }
                    title,
                    description,
                    transformation_title,
                    transformation_description,
                    opinion {
                        name,
                        job_position,
                        message,
                        photo {
                            name
                            localFile {
                                publicURL
                            }
                        },
                        company_logo {
                            name
                            localFile {
                                publicURL
                            }
                        },
                        locale
                    }
                    reasons_title,
                    reasons {
                        order,
                        title,
                        description
                    },
                    innovation_title,
                    innovation_description,
                    innovation_sentence,
                    partner_title,
                    partner_description
                }
            }
        }
        banner: markdownRemark(
            frontmatter: {
                lang: { eq: $lang }
                name: {
                    eq: "why-tecalis/banner"
                }
            }) {
    frontmatter {
    title
    description
    imageUrl
}
}
    counters: markdownRemark(
    frontmatter: {
    lang: {
    eq: $lang
}
    name: {
    eq: "counters"
}
}) {
    frontmatter {
    title
    counters{
    first {
    headingTo
    heading
}
    second {
    headingTo
    heading
}
    third {
    headingTo
    heading
}
    fourth {
    headingTo
    heading
}
    fifth {
    headingTo
    heading
}
}
}
}
    numbers: markdownRemark(
    frontmatter: {
    lang: {
    eq: $lang
}
    name: {
    eq: "why-tecalis/numbers"
}
}) {
    frontmatter {
    title
    numbers{
    first {
    heading
    text
}
    second {
    heading
    text
}
    third {
    heading
    text
}
    fourth {
    heading
    text
}
    fifth {
    heading
    text
}
    sixth {
    heading
    text
}
}
}
}
    circles: markdownRemark(
    frontmatter: {
    lang: {
    eq: $lang
}
    name: {
    eq: "why-tecalis/circles"
}
}) {
    frontmatter {
    heading
    text
    circles{
    first {
    text
}
    second {
    text
}
    third {
    text
}
    fourth {
    text
}
    fifth {
    text
}
    sixth {
    text
}
}
}
}
    textLead: markdownRemark(
    frontmatter: {
    lang: {
    eq: $lang
}
    name: {
    eq: "why-tecalis/text-lead"
}
}) {
    frontmatter {
    heading
}
    html
}
    textLeadFixed: markdownRemark(
    frontmatter: {
    lang: {
    eq: $lang
}
    name: {
    eq: "why-tecalis/text-lead-fixed"
}
}) {
    frontmatter {
    heading
}
    html
}
    textImage: markdownRemark(
    frontmatter: {
    lang: {
    eq: $lang
}
    name: {
    eq: "why-tecalis/text-image"
}
}) {
    frontmatter {
    heading
}
    html
}
    textImage2: markdownRemark(
    frontmatter: {
    lang: {
    eq: $lang
}
    name: {
    eq: "why-tecalis/text-image2"
}
}) {
    frontmatter {
    heading
}
    html
}
    cookies: markdownRemark(
    frontmatter: {
    lang: {
    eq: $lang
}
    name: {
    eq: "cookies"
}
}) {
    frontmatter {
    cookiesStart
    cookiesEnd
    linkText
}
}
}`;
;
